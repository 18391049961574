<template>
  <Layout>
    <template #default>
      <h2 class="heading-3">Update payment method</h2>
    </template>

    <template #aside>
      <div class="space-y-16">
        <h2 class="heading-3">{{ i18n(translations.summaryTitle) }}</h2>

        <DueSummary v-if="data" :payment="data.payment" :policy="data.policy" />
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { useRouteParams } from '#imports'

import { insurancesAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import Layout from '~/scopes/insurance/components/Layout/Layout.vue'

import translations from './DueInsurance.translations'
import DueSummary from './components/DueSummary/DueSummary.vue'

const { policyId } = useRouteParams()

const { data } = useHttpFetch(insurancesAPI.getInsuranceDuePayment, {
  pathParams: { policyId },
  // only for testing purpose, should be removed
  server: false,
})

const i18n = useI18n()
</script>
