import { createHttpEndpoint } from '../../utils'

import type { DuePayment, DuePolicy } from './insurances.types'

/**
 * Retrieve the bills associated to an insurance
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/insurance-internal-v1-api/definition#operation/orderline-insurance-bills}
 */
export const getBills = createHttpEndpoint({
  method: 'GET',
  operationId: 'getBills',
  path: '/bm/orderline/:orderlineId/insurance_bill/:fileType',
})

/**
 * Retrieve the missing occurences from a policy
 *
 * @see {@link }
 */
export const getInsuranceDuePayment = createHttpEndpoint<{
  policy: DuePolicy
  payment: DuePayment
}>({
  method: 'GET',
  operationId: 'getInsuranceDuePayment',
  path: '/bm/insurances/v2/policies/:policyId/due-payments',
})
