import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  occurence: {
    id: 'insurance_missed_occurrence',
    defaultMessage: 'Missed charge on {date}',
  },
  product: {
    id: 'insurance_summary_for_product',
    defaultMessage: 'For {model}',
  },
  purchaseDate: {
    id: 'insurance_summary_purchase_date',
    defaultMessage: 'Purchased on {date}',
  },
  recurringPaymentDetails: {
    id: 'summary_missed_occurrence_info',
    defaultMessage: 'You’re only paying for recurring payments you’ve missed.',
  },
}

export default translations
